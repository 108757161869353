*,
body {
  font-family: 'Manrope', sans-serif;
  color: #4a4f5c;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3b6cdb;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3b6cdb;
  border-radius: 6px;
}

ul,
li,
ol {
  font-family: 'Manrope', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800 !important;
}

h1 {
  font-size: 32px !important;
}

h2 {
  font-size: 27px !important;
}

h3 {
  font-size: 24px !important;
}

h5 {
  font-size: 20px !important;
}

h6 {
  font-size: 16px !important;
}

.subTitle {
  font-size: 18px;
  font-weight: 800;
}

.smallBold {
  font-size: 14px;
  font-weight: 700;
}

.bodyL {
  font-size: 16px;
}

.bodyM {
  font-size: 14px;
}

.bodyS {
  font-size: 12px;
}

.danger {
  color: #ff3333;
}

.sucessfull {
  color: #03dd6e;
}

.alerts {
  color: #fdd63c;
}

.mideful {
  color: #ff5833;
}

.actives {
  color: #437af8 !important;
}

.bgLight {
  background-color: #f4f4f7;
}

.lightGrey {
  color: #ababab;
}

.bRadius {
  border-radius: 16px;
}

.bRadius8 {
  border-radius: 8px;
}

.mVgap {
  margin: 4px 0;
}

.allCap {
  text-transform: uppercase;
}

.smallSubT {
  font-size: 16px;
  font-weight: 600;
  color: #ababab;
  text-transform: capitalize;
}
.text-16 {
  font-size: 16px;
  color: #000;
}

.disables {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.helpText {
  font-size: 12px;
  color: #ababab;
  margin: 0px;
}

.m-3 {
  margin: 0.3rem 1rem !important;
}

.greenBg {
  background-color: #03dd6e !important;
}

.blueBg {
  background-color: #437af8 !important;
}

.yellowBg {
  background-color: #fdd63c !important;
}

.stickyTop {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  width: 100%;
  z-index: 20;
  background-color: #fff;
}

.contentArea {
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
  flex: 1;
}

.cursorP {
  cursor: pointer;
}

.ml-1 {
  margin-left: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.mVgap {
  margin: 4px 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.dualCTA {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
}

a {
  font-weight: 500;
  text-decoration: none !important;
}

.bigCTAprimary {
  font-size: 1rem;
  padding: 10px 24px !important;
  background: #437af8;
  color: #ffffff;
  border: solid 1px #437af8;
  border-radius: 24px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.smallCTAprimary {
  font-size: 0.875rem;
  padding: 10px 24px !important;
  background: #437af8;
  color: #fff;
  border: solid 1px #437af8;
  border-radius: 24px;
  font-weight: 600;
}

.bigCTAsecondary {
  font-size: 1rem;
  padding: 10px 24px !important;
  background: #fff;
  color: #437af8;
  border: solid 1px #437af8;
  border-style: inset;
  border-radius: 24px;
  font-weight: 600;
}

.smallCTAsecondary {
  font-size: 0.875rem !important;
  padding: 10px 24px !important;
  background: #fff !important;
  color: #437af8 !important;
  border: solid 1px #437af8 !important;
  border-radius: 24px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.tertiaryBtn {
  color: #437af8;
  background-image: url(./assets/images/chevron-right.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
}

.tertiaryBtn:hover {
  border-bottom: solid 1px #437af8;
}

.smallCTAdanger {
  font-size: 0.875rem;
  padding: 10px 24px !important;
  background: #fff2f2;
  color: #ff3333;
  border-radius: 24px;
  text-align: center;
  border: solid 1px #fff2f2;
  font-weight: 600;
}

.smallCTAdanger:hover {
  color: #ff3333;
  border: solid 1px #fff2f2;
  background-color: #ffe5e5;
}

.xsSecondary {
  font-size: 12px;
  padding: 4px 8px 2px 8px !important;
  background: #fff;
  color: #437af8;
  border: solid 1px #437af8;
  border-radius: 24px;
  text-align: center;
  font-weight: 600;
}

.xsCTA {
  font-size: 0.875rem;
  color: #8f8f8f;
  font-weight: 500;
}

.bigCTAprimary:hover,
.smallCTAprimary:hover {
  background: #3b6cdb;
  color: #fff;
}

.bigCTAsecondary:hover,
.smallCTAsecondary:hover {
  background: #fafbff;
  color: #437af8;
}

/* CSS for Forms Fields */

.formSet {
  width: 100%;
  font-size: 14px;
}

.formSet input[type='checkbox'] {
  cursor: pointer;
}

.formSet input[type='text'],
.formSet input[type='search'],
.formSet input[type='tel'],
.formSet input[type='password'],
.formSet input[type='textarea'] {
  width: 100%;
  background-color: #f4f4f7;
  border: solid 1px #eeeeee;
  padding: 8px 16px;
  border-radius: 8px;
}

.formSet input[type='text']:focus,
.formSet input[type='tel']:focus,
.formSet input[type='password']:focus,
.formSet input[type='search']:focus .formSet input[type='password']:hover,
.formSet input[type='password']:focus,
.formSet input[type='textarea']:hover,
.formSet input[type='search']:hover,
.formSet select:focus,
.formSet select:hover {
  background: #f9f9f9;
  border: solid 1px #eeeeee;
  outline: none;
  box-shadow: none;
}

.formSet input[type='search'] {
  background-image: url('./assets/images/search-md-icon.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.formSet input[type='checkbox']:focus,
.formSet input[type='checkbox']:hover {
  outline: none;
  box-shadow: none;
}

.formSet label {
  font-size: 0.875rem;
  margin: 0.5rem auto 0.25rem auto;
  color: #4a4f5c;
  font-weight: 500;
}

.formSet .bodyM label {
  color: #8f8f8f;
  font-size: 14px;
}

.formSet input[type='search'] {
  background-image: url('./assets/images/search-md-icon.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.formSet a {
  text-decoration: none;
}

.formSet input::placeholder {
  color: #ababab;
  font-size: 14px;
}

.formSet .textarea {
  min-height: 120px;
  width: 100%;
  background-color: #f4f4f7;
  border: solid 1px #eeeeee;
  padding: 8px 16px;
  border-radius: 8px;
}

.formSet select {
  width: 100%;
  background-color: #f4f4f7;
  border: solid 1px #eeeeee;
  padding: 8px 16px;
  border-radius: 8px;
}

.btn-close-flex {
  display: flex;
  justify-content: end;
  padding: 10px 15px 0 0;
}

.dashBoard {
  background-image: url(./assets/images/dashboard_icon.svg);
}

.jobposition {
  background-image: url(./assets/images/suitcase_icon.svg);
}

.credits {
  background-image: url(./assets/images/coins_icon.svg);
}

.navBottomLinks {
  padding: 0px;
  list-style: none;
}

.navBottomLinks a {
  color: #4a4f5c;
}

.navBottomLinks li {
  margin-top: 16px;
}

/* Modal CSS */

.modal-content {
  border: none !important;
  border-radius: 1rem !important;
  padding: 10px;
}

.modal-header {
  border-bottom: none;
  padding: 0;
  padding: 16px;
}

.modal-body {
  padding: 0 20px 16px 20px;
}

.resume-page .modal-dialog {
  width: 100% !important;
}

@media (min-width: 576px) {
  .resume-page .modal-dialog {
    max-width: 1000px !important;
  }
}

@media (max-width: 575px) {
  .resume-page .modal-dialog {
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.error-toaster {
  color: #ff0000;
  border: solid 1px #fff2f2;
  background-color: #ffe5e5;
  border-radius: 3px;
  margin-top: 3px;
  padding: 1px 5px;
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 600;
}

/* React Redux Toastr Css */
.redux-toastr .toastr .rrt-middle-container .rrt-title {
  color: white;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
}

@media (min-width: 768px) {
  /* Modal */
  .modal-md {
    width: 400px;
  }

  .mVgap {
    margin: 0px 0;
  }

  .sideNav {
    display: block;
    width: 220px;
  }

  .onboardCover {
    display: block;
  }

  .contentArea {
    /* margin-left: 220px; */
    position: relative;
    flex: 1 !important;
  }

  .profileNav {
    color: #4a4f5c;
    display: flex;
    justify-items: baseline;
    gap: 8px;
    padding-left: 16px;
    border-left: solid 1px #f3f3f3;
    width: fit-content;
    padding-right: 20px;
  }

  .profileNav span {
    display: block;
    color: #8f8f8f;
  }

  .profileNav.active > * {
    color: #fff !important;
  }

  .profileNav.active span {
    color: #fff !important;
  }

  .dashCards {
    min-height: 60vh;
  }
}

@media (max-width: 768px) {
  .px-0-important {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-0-important {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.job-description-pdf > * {
  font-size: 50px !important;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  overflow-wrap: break-word; /* Modern browsers */
}

canvas.react-pdf__Page__canvas {
  width: 100% !important;
}

.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.text-red {
  color: red !important;
}

.custom-border {
  border: 1px solid #7f7f7f;
}

.text-white {
  color: #fff !important;
}

.heroCTA {
  margin: 60px auto;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  padding: 36px 0px 16px 0;
}
