.navbar {
    background: #fff;
    height: 7vh;
}

.nav-link {
    color: #4A4F5C;
}

.nav-link:hover {
    color: #437AF8;
}

.nav-link {
    display: block;
    padding: 10px 13px !important;
    margin: 0 0 0 8px;
    border-radius: 24px;
    text-align: center;
}