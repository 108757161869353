.coinNav {
  font-size: 20px;
  font-weight: 600;
  min-width: 60px;
  width: 100px !important;
  max-width: 400px;
  line-height: 100%;
  padding: 0;
}

.coinNav span {
  font-size: 16px !important;
  font-weight: 400;
  color: #141414;
  display: block;
  line-height: 100%;
  display: inline-block;
  width: 100%;
  font-weight: 500;
}
