.smallTextRangeTitle {
  font-size: 14px;
  font-weight: 600;
}

.addPosition {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 16px;
}

.personality-bar-box {
  width: 100%;
}

.personality-bar-inner-box {
  display: flex;
  justify-content: space-between;
  margin: 12px 0px;
}

.personality-bars {
  width: 100%;
  height: 12px;
  background-color: #eee;
  cursor: pointer;
}

.personality-bars.cstm-height {
  height: 6px;
  cursor: default;
}

.br-left {
  border-radius: 6px 0px 0px 6px;
}

.br-right {
  border-radius: 0px 6px 6px 0px;
}

.meterValue {
  padding: 16px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 16px;
}

.meterValue div {
  float: left;
  margin-right: 20px;
}

.tabs {
  border-bottom: solid 1px #f4f4f7;
  padding-top: 20px;
}

.tabs span,
.tabs a {
  text-transform: uppercase;
  margin-right: 32px;
  padding: 8px 0;
  display: inline-block;
  font-size: 14px;
  color: #8f8f8f;
  font-weight: 600;
  cursor: pointer;
}

.tabs .active {
  border-bottom: solid 2px #437af8;
  color: #437af8;
}

.cardControls {
  margin: 16px auto;
}

.controlls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 16px;
  color: #8f8f8f;
  font-size: 14px;
}

.controlls a {
  color: #4a4f5c;
}

.jobCards {
  padding: 8px 0 0 0;
  border-radius: 16px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.department {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.jobNumbers {
  font-size: 20px;
  font-weight: 800;
}

.jobNumbers span {
  font-size: 12px;
  display: block;
  font-weight: 400;
}

.vLine {
  height: 40px;
  width: 1px;
  background-color: #ababab;
}

.jobPosition {
  font-size: 14px;
}

.jNumb {
  padding: 8px 16px;
}

.cardFoot {
  display: block;
  border-top: solid 1px #eee;
  background-color: #fff;
  text-align: center;
  padding: 8px 0;
  border-radius: 0 0 16px 16px;
}

.cardFoot a {
  padding: 4px 20px;
}

.jobCardsClose {
  background-color: #f4f4f7;
  border-radius: 16px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.titlesBack {
  display: flex;
  gap: 4px;
  align-items: center;
}

.titlesBack p {
  margin: 0;
  font-size: 18px;
  font-weight: 800;
  line-height: 100%;
}

.titlesBack span {
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
}

.jobDiscription {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 16px;
}

.addPosition {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 16px;
}

.background-yellow {
  background-color: #fdd63c;
}

.background-blue {
  background-color: #437af8;
}

.background-green {
  background-color: #03dd6e;
}

.traitGraph {
  display: flex;
  align-items: center;
  gap: 2px;
}

.traitGraph span {
  width: 33%;
  height: 6px;
  background-color: #eee;
  display: inline-block;
}

.traitGraph span:first-child {
  border-radius: 3px 0 0 3px;
}

.traitGraph span:last-child {
  border-radius: 0 3px 3px 0;
}

.traitValue {
  display: flex;
  align-items: center;
  gap: 2px;
}

.traitValue span {
  width: 33%;
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

.uploadProgess {
  height: 20px;
  border-radius: 30px;
}

.tableControl {
  display: flex;
  justify-content: space-between;
  margin: 16px auto;
}

.tableConLeft {
  display: flex;
  gap: 24px;
  width: 70%;
}

.tableSelectProgress {
  width: 35%;
}

.progressWrap {
  display: flex;
  gap: 8px;
  align-items: normal;
  position: relative;
}

.progress {
  height: 5px !important;
  width: 100%;
  margin-top: 8px;
}

.progress.cstmProgessResume {
  height: 7px !important;
}

.progress-bar {
  background-color: #437af8 !important;
}

.progress-bar-tooltip {
  font-weight: 900;
  color: #437af8;
  font-size: 14px;
}

.tableControl p {
  margin: 0 0 8px 0;
}

#resumeFilter {
  display: flex;
  gap: 4px;
  align-items: baseline;
}

#resumeFilter button {
  border: solid 1px #ababab;
  border-radius: 24px;
  font-size: 12px;
  background-color: #fff;
  padding: 2px 8px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  color: #ababab;
  transition: 200ms;
}

#resumeFilter button.clear {
  border-color: #fd6b6b !important;
  background-color: #ff9696;
  color: white;
}

.selected {
  background-image: url(../../../../assets/images/check.svg);
  background-repeat: no-repeat;
  background-position: 4px center;
  background-color: #fdd63c !important;
  padding: 2px 8px 2px 20px !important;
  border: solid 1px #fdd63c !important;
  color: #4a4f5c !important;
}

#creditFilter {
  display: flex;
  gap: 4px;
  align-items: baseline;
}

#creditFilter button {
  border: solid 1px #eeeeee;
  border-radius: 24px;
  font-size: 16px;
  background-color: #fff;
  padding: 8px 20px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  color: #4a4f5c;
  transition: 200ms;
  display: inline-block;
}

#creditFilter button img {
  width: 14px;
}

#creditFilter button p {
  margin: 0;
  font-size: 12px;
}

.selects {
  background-color: #437af8 !important;
  border: solid 1px #437af8 !important;
  color: #fff !important;
}

.table > thead {
  vertical-align: middle;
  color: #8f8f8f;
  font-size: 14px;
  font-weight: 500;
}

.table > tbody {
  vertical-align: middle;
}

.table > :not(caption) > * > * {
  border-bottom: solid 1px #eee !important;
}

.resumeTable th {
  position: relative;
}

.resumeTable th a {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
}

.resumeTable {
  border: solid 1px #f3f3f3;
  border-radius: 16px;
}

.rank {
  font-size: 12px;
  background-color: #8f8f8f;
  color: #fff;
  font-weight: 600;
  padding: 2px;
  border-radius: 20px;
  display: inline-block;
  min-width: 22px;
  text-align: center;
  margin: 0;
}

.textWrapBor {
  padding: 16px;
  border: solid 1px #f3f3f3;
  border-radius: 16px;
}

.selectCadidate {
  color: #437af8;
}

.cadidateInfo {
  font-size: 14px;
  text-transform: uppercase;
  color: #8f8f8f;
  font-weight: 600;
}

.cadidateInfo p {
  font-size: 18px;
  font-weight: 800;
  color: #4a4f5c;
  text-transform: capitalize;
  margin: 0;
}

.personalities {
  border: none !important;
  border-radius: 22px !important;
  width: 880px;
  padding: 16px;
  box-shadow: 0px 6px 15px 5px rgb(168 168 168 / 30%);
  padding: 20px !important;
  transform: translate(20%, 20%) !important;
}

.personalities p {
  font-size: 14px;
  font-weight: 700;
}

.personalities span {
  font-weight: 400;
  color: #8f8f8f;
}

.personalities .col-md {
  width: 170px;
}

.personalityPie {
  position: relative;
}

.greenCheck {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #03dd6e;
  display: block;
  border: solid 2px #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 110px;
  top: 34px;
}

.blueCheck {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #437af8;
  display: block;
  border: solid 2px #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 66px;
  top: 12px;
}

.yellowCheck {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #fdd63c;
  display: block;
  border: solid 2px #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 22px;
  top: 34px;
}

.legends {
  font-weight: 600;
  width: 100%;
  text-align: left;
}

.meterValue {
  padding: 16px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 16px;
}

.meterValue div {
  float: left;
  margin-right: 20px;
}

.personalityValue button {
  width: 33%;
  height: 12px;
  border: none;
  border-radius: 24px;
  font-size: 12px;
  background-color: #eeeeee;
  padding: 2px 8px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  transition: 200ms;
}

.personalitySelect {
  background-color: #3b6cdb !important;
}

.hide {
  visibility: hidden;
}

.uploadFiles {
  display: flex;
  border-radius: 16px;
  height: 180px;
  background-color: #eee;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.file-upload-progess-list {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 200px;
  height: 150px;
  text-align: left;
  overflow-y: auto;
}

.desiredBtn {
  background-image: url('../../../../assets/images/arrow-white-right.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 30px;
  width: 235px;
  background-position-x: 95%;
  text-align: left;
}
