.onboardCover {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    border-radius: 24px;
    background: #4A4F5C;
    display: none;
}

.onboardCover img {
    object-fit: cover;
    min-height: 100%;
    object-position: left top;
}

.onboardForm {
    width: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
}

/* Custom Country Code input  */
.formSet .intl-tel-input {
    display: block;
}

/* Custom Form error handler */
.formSet .form-control-cstm,
.formSet .form-control.form-control-cstm:valid,
.formSet .form-control.form-control-cstm:valid:focus {
    border-color: var(--bs-form-invalid-border-color) !important;
    box-shadow: none;
    background-image: none;
}

@media (min-width: 768px) {
    .onboardCover {
        display: block;
    }

}