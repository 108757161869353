@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
body {
  font-family: "Manrope", sans-serif;
  color: #4a4f5c;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3b6cdb;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3b6cdb;
  border-radius: 6px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 27px;
}
h3 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}
.subTitle {
  font-size: 18px;
  font-weight: 800;
}
.subTitle2 {
  font-size: 24px;
  font-weight: 800;
}
.smallBold {
  font-size: 12px;
  font-weight: 700;
}

.bodyL {
  font-size: 16px;
}
.bodyM {
  font-size: 14px;
}
.bodyS {
  font-size: 12px;
}
.danger {
  color: #ff3333;
}
.sucessfull {
  color: #03dd6e;
}
.alerts {
  color: #fdd63c;
}
.mideful {
  color: #ff5833;
}
.actives {
  color: #437af8;
}
.bRadius {
  border-radius: 16px;
}
.bRadius8 {
  border-radius: 8px;
}
.mVgap {
  margin: 4px 0;
}
.bgLight {
  background-color: #f4f4f7;
}
.lightGrey {
  color: #ababab;
}
.allCap {
  text-transform: uppercase;
}
.smallSubT {
  font-size: 14px;
  font-weight: 600;
  color: #ababab;
  text-transform: uppercase;
}
.disables {
  opacity: 0.5;
}
.helpText {
  font-size: 12px;
  color: #ababab;
  margin: 0px;
}
.m-3 {
  margin: 0.3rem 1rem !important;
}
.greenBg {
  background-color: #03dd6e !important;
}
.blueBg {
  background-color: #437af8 !important;
}
.yellowBg {
  background-color: #fdd63c !important;
}
.whiteBg {
  background-color: #fff;
}

.stickyTop {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  width: 100%;
  z-index: 3;
  background-color: #fff;
}
.contentArea {
  position: relative;
}

.cursorP {
  cursor: pointer;
}

.ml-1 {
  margin-left: 8px;
}
.ml-2 {
  margin-left: 16px;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.dualCTA {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
}

a {
  font-weight: 500;
  text-decoration: none;
}
.navbar {
  background: #fff;
}
.nav-link {
  color: #4a4f5c;
}
.nav-link:hover {
  color: #437af8;
}
.nav-link {
  display: block;
  padding: 10px 13px !important;
  margin: 0 0 0 8px;
  border-radius: 24px;
  text-align: center;
}
.bigCTAprimary {
  font-size: 1rem;
  padding: 10px 24px !important;
  background: #437af8;
  color: #ffffff;
  border: solid 1px #437af8;
  border-radius: 24px;
  display: block;
  text-align: center;
}
.smallCTAprimary {
  font-size: 0.875rem;
  padding: 10px 24px !important;
  background: #437af8;
  color: #fff;
  border: solid 1px #437af8;
  border-radius: 24px;
  font-weight: 600;
}
.smallCTAprimaryV2 {
  font-size: 0.875rem;
  padding: 10px 24px !important;
  background: #437af8;
  color: #fff;
  border: solid 1px #437af8;
  border-radius: 24px;
  font-weight: 600;
  border: solid 1px #437af8 !important;
  color: #437af8 !important;
}
.bigCTAsecondary {
  font-size: 1rem;
  padding: 10px 24px !important;
  background: #fff;
  color: #437af8;
  border: solid 1px #437af8;
  border-style: inset;
  border-radius: 24px;
  font-weight: 600;
}
.smallCTAsecondary {
  font-size: 0.875rem;
  padding: 10px 24px !important;
  background: #fff;
  color: #437af8;
  border: solid 1px #437af8;
  border-radius: 24px;
  text-align: center;
  font-weight: 600;
}
.tertiaryBtn {
  color: #437af8;
  background-image: url(./assets/images/chevron-right.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
}
.tertiaryBtn:hover {
  border-bottom: solid 1px #437af8;
}
.smallCTAdanger {
  font-size: 0.875rem;
  padding: 10px 24px !important;
  background: #fff2f2;
  color: #ff3333;
  border-radius: 24px;
  text-align: center;
  border: solid 1px #fff2f2;
  font-weight: 600;
}
.smallCTAdanger:hover {
  color: #ff3333;
  border: solid 1px #fff2f2;
  background-color: #ffe5e5;
}
.xsSecondary {
  font-size: 12px;
  padding: 4px 8px 2px 8px !important;
  background: #fff;
  color: #437af8;
  border: solid 1px #437af8;
  border-radius: 24px;
  text-align: center;
  font-weight: 600;
}

.xsCTA {
  font-size: 0.875rem;
  color: #8f8f8f;
  font-weight: 500;
}

.bigCTAprimary:hover,
.smallCTAprimary:hover {
  background: #3b6cdb;
  color: #fff;
}
.bigCTAsecondary:hover,
.smallCTAsecondary:hover {
  background: #fafbff;
  color: #437af8;
}

.modal-content {
  border: none !important;
  border-radius: 1rem;
}
.modal-header {
  border-bottom: none;
  padding: 0;
  padding: 16px;
}
.modal-body {
  padding: 0 20px 16px 20px;
}

.navSep {
  padding-top: 66px;
}
.onboardCover {
  width: 100%;
  height: 96vh;
  overflow: hidden;
  border-radius: 24px;
  background: #4a4f5c;
  display: none;
}
.onboardCover img {
  object-fit: cover;
  min-height: 100%;
  object-position: left top;
}

.onboardForm {
  width: 100%;
  min-height: 98vh;
  display: flex;
  align-items: center;
}
.formSet {
  width: 100%;
  font-size: 14px;
}
.formSet input[type="text"],
.formSet input[type="search"],
.formSet input[type="tel"],
.formSet input[type="password"],
.formSet input[type="textarea"] {
  width: 100%;
  background-color: #f4f4f7;
  border: solid 1px #eeeeee;
  padding: 8px 16px;
  border-radius: 8px;
}
.formSet input[type="text"]:focus,
.formSet input[type="tel"]:focus,
.formSet input[type="password"]:hover,
.formSet input[type="password"]:focus,
.formSet input[type="textarea"]:hover {
  background: #f9f9f9;
  border: solid 1px #eeeeee;
  outline: none;
}
.formSet label {
  font-size: 0.875rem;
  margin: 0.5rem auto 0.25rem auto;
  color: #4a4f5c;
  font-weight: 500;
}

.formSet input[type="search"] {
  background-image: url(./assets/images/search-md-icon.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.uploadFiles {
  display: flex;
  border-radius: 16px;
  height: 180px;
  background-color: #eee;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.formSet select {
  width: 100%;
  background-color: #f4f4f7;
  border: solid 1px #eeeeee;
  padding: 8px 16px;
  border-radius: 8px;
}
.textarea {
  min-height: 120px;
  width: 100%;
  background-color: #f4f4f7;
  border: solid 1px #eeeeee;
  padding: 8px 16px;
  border-radius: 8px;
}

.sideNav {
  background-color: #f9f9f9;
  position: fixed;
  display: none;
}
.sideNav .nav-item {
  width: 100%;
}
.sideNavItems {
  background-repeat: no-repeat;
  background-position: 16px 12px;
  display: block;
  padding: 10px 16px 10px 46px;
  transition: all;
  transition-duration: 0.5s;
  border-radius: 24px;
  color: #4a4f5c;
}
.sideNavItems:hover,
.nav-item .active {
  background-position: 16px -22px;
  background-color: #437af8;
  color: #fff;
  border-radius: 24px;
}

.dashBoard {
  background-image: url(./assets/images/dashboard_icon.svg);
}
.jobposition {
  background-image: url(./assets/images/suitcase_icon.svg);
}
.credits {
  background-image: url(./assets/images/coins_icon.svg);
}

.navBottomLinks {
  padding: 0px;
  list-style: none;
}
.navBottomLinks a {
  color: #4a4f5c;
}
.navBottomLinks li {
  margin-top: 16px;
}

.coinNav {
  font-size: 20px;
  font-weight: 600;
  width: 100px !important;
  line-height: 100%;
  padding: 0;
}
.coinNav span {
  font-size: 14px !important;
  font-weight: 400;
  color: #212121;
  display: block;
  line-height: 100%;
}

.features {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background: var(--white, #fff);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
}
.features span {
  display: block;
  font-size: 28px;
  font-weight: 800;
}

.dashCards {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #f4f4f7;
  background: #fff;
  max-height: 424px;
  overflow-y: scroll;
}
.cardHeads {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.dashCards ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cardItem {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  margin-top: 8px;
  color: #8f8f8f;
  text-transform: uppercase;
  font-size: 14px;
}
.cardItem p,
.videoCards p {
  margin: 0;
  font-weight: 800;
  color: #4a4f5c;
  text-transform: capitalize;
  font-size: 16px;
}
.cardItem span {
  margin-left: 20px;
}
.progress {
  height: 4px;
  width: 100%;
  margin-top: 8px;
}
.progress-bar {
  background-color: #437af8;
}

.videoCards {
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #eee;
  margin-top: 8px;
  background-color: #fff;
  display: block;
}
.videoDashCover {
  background-size: cover;
  background-position: center center;
  background-color: rgba(99, 65, 0, 0.5);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 4px;
}
.videoinfo {
  font-weight: 600;
  color: #fff;
}
.videoDate {
  position: absolute;
  font-size: 10px;
  color: #fff;
  right: 8px;
  bottom: 8px;
}
.dipartment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  color: #8f8f8f;
}

.cadidateInfo {
  font-size: 14px;
  text-transform: uppercase;
  color: #8f8f8f;
  font-weight: 600;
}
.cadidateInfo p {
  font-size: 18px;
  font-weight: 800;
  color: #4a4f5c;
  text-transform: capitalize;
  margin: 0;
}

.tabs {
  border-bottom: solid 1px #f4f4f7;
}
.tabs a {
  text-transform: uppercase;
  margin-right: 32px;
  padding: 8px 0;
  display: inline-block;
  font-size: 14px;
  color: #8f8f8f;
  font-weight: 600;
}
.tabs .active {
  border-bottom: solid 2px #437af8;
  color: #437af8;
}
.cardControls {
  margin: 16px auto;
}
.controlls {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 16px;
  color: #8f8f8f;
  font-size: 14px;
}
.controlls a {
  color: #4a4f5c;
}

.jobCards {
  padding: 8px 0 0 0;
  border-radius: 16px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.department {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.jobNumbers {
  font-size: 20px;
  font-weight: 800;
}
.jobNumbers span {
  font-size: 12px;
  display: block;
  font-weight: 400;
}
.vLine {
  height: 40px;
  width: 1px;
  background-color: #ababab;
}
.jobPosition {
  font-size: 14px;
}
.jNumb {
  padding: 8px 16px;
}
.cardFoot {
  display: block;
  border-top: solid 1px #eee;
  background-color: #fff;
  text-align: center;
  padding: 8px 0;
  border-radius: 0 0 16px 16px;
}
.cardFoot a {
  padding: 4px 20px;
}

.jobCardsClose {
  background-color: #f4f4f7;
  border-radius: 16px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.titlesBack {
  display: flex;
  gap: 4px;
  align-items: center;
}
.titlesBack p {
  margin: 0;
  font-size: 18px;
  font-weight: 800;
  line-height: 100%;
}
.titlesBack span {
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
}
.jobDiscription {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 16px;
}

.addPosition {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 16px;
}

.traitGraph {
  display: flex;
  align-items: center;
  gap: 2px;
}
.traitGraph span {
  width: 33%;
  height: 6px;
  background-color: #eee;
  display: inline-block;
}
.traitGraph span:first-child {
  border-radius: 3px 0 0 3px;
}
.traitGraph span:last-child {
  border-radius: 0 3px 3px 0;
}
.traitValue {
  display: flex;
  align-items: center;
  gap: 2px;
}
.traitValue span {
  width: 33%;
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

.uploadProgess {
  height: 20px;
  border-radius: 30px;
}

.tableControl {
  display: flex;
  justify-content: space-between;
  margin: 16px auto;
}
.tableConLeft {
  display: flex;
  gap: 24px;
  width: 70%;
}
.tableSelectProgress {
  width: 40%;
}
.progressWrap {
  display: flex;
  gap: 8px;
  align-items: normal;
  position: relative;
}
.progress span {
  position: absolute;
  top: 16px;
  width: 100%;
  font-weight: 700;
  color: #437af8;
}
.tableControl p {
  margin: 0 0 8px 0;
}
#resumeFilter {
  display: flex;
  gap: 4px;
  align-items: baseline;
}
#resumeFilter button {
  border: solid 1px #ababab;
  border-radius: 24px;
  font-size: 12px;
  background-color: #fff;
  padding: 2px 8px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  color: #ababab;
  transition: 200ms;
}

.selected {
  background-image: url(./assets/images/check.svg);
  background-repeat: no-repeat;
  background-position: 4px center;
  background-color: #fdd63c !important;
  padding: 2px 8px 2px 20px !important;
  border: solid 1px #fdd63c !important;
  color: #4a4f5c !important;
}

#creditFilter {
  display: flex;
  gap: 4px;
  align-items: baseline;
}
#creditFilter button {
  border: solid 1px #eeeeee;
  border-radius: 24px;
  font-size: 16px;
  background-color: #fff;
  padding: 8px 20px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  color: #4a4f5c;
  transition: 200ms;
  display: inline-block;
}
#creditFilter button img {
  width: 14px;
}
#creditFilter button p {
  margin: 0;
  font-size: 12px;
}
.selects {
  background-color: #437af8 !important;
  border: solid 1px #437af8 !important;
  color: #fff !important;
}

.table > thead {
  vertical-align: middle;
  color: #8f8f8f;
  font-size: 14px;
  font-weight: 500;
}
.table > tbody {
  vertical-align: middle;
}
.table > :not(caption) > * > * {
  border-bottom: solid 1px #eee !important;
}
.resumeTable th {
  position: relative;
}
.resumeTable th a {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
}
.resumeTable {
  border: solid 1px #f3f3f3;
  border-radius: 16px;
}
.rank {
  font-size: 12px;
  background-color: #8f8f8f;
  color: #fff;
  font-weight: 600;
  padding: 2px;
  border-radius: 20px;
  display: inline-block;
  min-width: 22px;
  text-align: center;
  margin: 0;
}

.textWrapBor {
  padding: 16px;
  border: solid 1px #f3f3f3;
  border-radius: 16px;
}
.selectCadidate {
  color: #437af8;
}
.personalities {
  margin-left: 25% !important;
  border: none;
  border-radius: 16px;
  width: 880px;
  padding: 16px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
}
.personalities p {
  font-size: 14px;
  font-weight: 700;
}
.personalities span {
  font-weight: 400;
  color: #8f8f8f;
}
.personalities .col {
  width: 170px !important;
}
.personalityPie {
  position: relative;
}
.greenCheck {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #03dd6e;
  display: block;
  border: solid 2px #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 110px;
  top: 34px;
}
.blueCheck {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #437af8;
  display: block;
  border: solid 2px #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 66px;
  top: 12px;
}
.yellowCheck {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #fdd63c;
  display: block;
  border: solid 2px #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  left: 22px;
  top: 34px;
}
.legends {
  font-weight: 600;
  width: 100%;
  text-align: left;
}
.meterValue {
  padding: 16px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 16px;
}
.meterValue div {
  float: left;
  margin-right: 20px;
}

.personalityValue button {
  width: 33%;
  height: 12px;
  border: none;
  border-radius: 24px;
  font-size: 12px;
  background-color: #eeeeee;
  padding: 2px 8px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  transition: 200ms;
}
.personalitySelect {
  background-color: #3b6cdb !important;
}
.hide {
  visibility: hidden;
}

.fairee-features {
  list-style: none;
  text-align: left;
  padding: 0;
}
.fairee-features li {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  color: #4a4f5c;
}
.introVideo iframe {
  width: 100%;
  aspect-ratio: 16/9;
  pointer-events: none;
  border-radius: 16px;
}
.heroCTA {
  margin: 60px auto;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  padding: 36px 0px 16px 0;
}
.advantages {
  background-image: url(./assets/images/yellowbg.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 60px 0 40px 0;
}
.advBox {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  padding: 16px 16px 8px 16px;
  margin-bottom: 20px;
}
.advBox ul {
  padding: 0 0 0 16px;
}
.advBox h5 {
  margin: 12px 0 8px 0;
  font-size: 18px;
}
.sectionGap {
  padding: 40px 0;
}
.augumentCover {
  height: 60vh;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  background-color: #03dd6e;
}
.augumentCover img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: top right;
}
.steps h3 {
  padding: 0px 20px;
}
.steps .accordion-button {
  background-color: #437af8 !important;
  border: none !important;
  color: #fff;
  box-shadow: none;
  border-radius: 16px 16px 0 0 !important;
}
.steps .accordion-item {
  border: none;
  margin: 1px auto;
}
.steps .collapsed {
  border-radius: 16px !important;
}
.steps .show {
  border-radius: 0 0 16px 16px !important;
  padding: 0px;
  background-color: #437af8;
  color: #fff;
}
.steps ul {
  padding: 8px 40px 0 40px;
}
.steps .accordion-body {
  padding: 0;
  font-size: 20px;
}

.testDrive {
  background-color: #f4f4f7;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 16px;
}
.testDrive h3 {
  margin: 0;
}
.testDrive ul {
  list-style: none;
}
.testDrive ul li {
  background-image: url(./assets/images/blue-check.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 24px;
  margin: 8px 0px;
}

.turboDrive {
  background-color: #3b6cdb;
  color: #fff;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 16px;
}
.turboDrive ul {
  list-style: none;
}
.turboDrive ul li {
  background-image: url(./assets/images/white-check.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 24px;
  margin: 8px 0px;
}

.faq {
  background-color: #f4f4f7;
}
.faq .accordion-button {
  border: none !important;
  color: #437af8;
  box-shadow: none;
  border-radius: 16px 16px 0 0 !important;
  background-color: #fff;
  font-weight: 800;
}
.faq .accordion-item {
  border: none;
  margin: 4px auto;
  background: none;
}
.faq .collapsed {
  border-radius: 16px !important;
}
.faq .show {
  border-radius: 0 0 16px 16px !important;
  padding: 0 16px 16px 16px;
  background-color: #fff;
  color: #8f8f8f;
}
.faq .accordion-body {
  padding: 0;
}

.getStart {
  background-color: #fdd63c;
  padding: 24px 20px;
  border-radius: 16px;
}
.getStart div {
  display: flex;
  justify-content: space-around;
  gap: 4px;
  align-items: center;
  text-align: center;
}

footer {
  background-color: #4a4f5c;
  padding: 16px 0;
}
footer a {
  color: #fff;
  margin-right: 16px;
}
footer hr {
  border: solid 1px #ababab;
}
footer p {
  color: #ababab;
}

.nav-tabs .nav-link {
  text-transform: uppercase !important;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 0;
  color: #8f8f8f;
}
.nav-tabs .active {
  border-bottom: solid 2px #437af8 !important;
  color: #437af8 !important;
}

.userMcard {
  border-radius: 16px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
}
.columnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nameicon {
  text-align: center;
}
.nameThum {
  width: 70px;
  height: 70px;
  font-size: 20px;
  font-weight: 600;
  color: #8f8f8f;
  background: #eee;
  border-radius: 60px;
  padding: 22px 20px;
  display: inline-block;
  margin-bottom: 16px;
}

.balanceCard {
  background-color: #fff5f3;
  border-radius: 16px;
  padding: 16px;
}
.companyDetails {
  padding: 16px;
  border-radius: 16px;
  border: solid 1px #eee;
}
.coinNumber {
  font-size: 40px;
  font-weight: 700;
}

.vSubmit {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: start;
}

.profileSec {
  padding: 20px;
  border: solid 1px #eeeeee;
  border-radius: 16px;
}
.profileTitle {
  display: flex;
  align-items: center;
  gap: 20px;
}
.profileSec h5 {
  line-height: 100%;
}

@media (min-width: 768px) {
  /* Modal */
  .modal-md {
    width: 400px;
  }
  .mVgap {
    margin: 0px 0;
  }
  .sideNav {
    display: block;
    width: 220px;
  }
  .onboardCover {
    display: block;
  }
  .contentArea {
    margin-left: 220px;
    position: relative;
  }
  .profileNav {
    color: #4a4f5c;
    display: flex;
    justify-items: baseline;
    gap: 8px;
    padding-left: 16px;
    border-left: solid 1px #f3f3f3;
  }
  .profileNav span {
    display: block;
    color: #8f8f8f;
  }

  .dashCards {
    min-height: 60vh;
  }
  .advantages {
    background-position: top right;
    padding: 60px 0 40px 0;
  }

  .visionBox {
    min-height: 228px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
  .nav-item {
    margin: 10px 0;
  }
}
