.loader_container {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doc_container {
  min-height: 400px;
}
