.formSet .dropdown-menu.show {
    height: 250px;
    overflow-y: auto;
    width: fit-content;
    overflow-x: hidden;
    box-shadow: 0px 0px 5px 1px #d1cdcd;
}

.phoneCodeWrapper {
    position: relative;
}

.formSet .dropdown {
    position: absolute;
    top: 15px;
    left: 10px;
    box-shadow: 0px 0px 5px 0px #d9d3d3;
}

.formSet .dropdown-toggle {
    background-color: white;
    color: black;
    border: none;
    font-size: 13px;
    padding: 2px 5px;
    font-weight: 500;
}

.formSet .form-control.cstmPaddingLeft {
    padding-left: 70px;
}

.formSet .tooltip {
    pointer-events: none;
}

.selectedDropdown.dropdown-item {
    background-color: #437af8;
    color: white;
}

@media (min-width: 992px) {

    .modal-dialog.modal-cstm {
        max-width: 70% !important;
        width: 50% !important;
    }
}