.sideNav {
    background-color: #f9f9f9;
    display: none;
}

.sideNav .nav-item {
    width: 100%;
}

.sideNavItems {
    background-repeat: no-repeat;
    background-position: 16px 12px;
    display: block;
    padding: 10px 16px 10px 46px;
    transition: all;
    transition-duration: 0.5s;
    border-radius: 24px;
    color: #4A4F5C;
}

.sideNavItems:hover,
.nav-item .active {
    background-position: 16px -22px;
    background-color: #437AF8;
    color: #fff;
    border-radius: 24px;
}